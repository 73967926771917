import request from '@/utils/request'

// 查询银行账号
export function listBankAccount(query) {
  return request({
    url: '/tms/bank_account/list',
    method: 'get',
    params: query
  })
}

// 查询个人银行账号
export function myListBankAccount(query) {
  return request({
    url: '/tms/bank_account/my_list',
    method: 'get',
    params: query
  })
}

// 新增网点银行账号
export function addNodeBankAccount(data) {
  return request({
    url: '/tms/bank_account/node',
    method: 'post',
    data: data
  })
}

// 新增客户银行账号
export function addCustomerBankAccount(data) {
  return request({
    url: '/tms/bank_account/customer',
    method: 'post',
    data: data
  })
}

// 新增个人客户银行账号
export function addMyCustomerBankAccount(data) {
  return request({
    url: '/tms/bank_account/my_customer',
    method: 'post',
    data: data
  })
}

// 平台删除银行账号
export function delBankAccount(id) {
  return request({
    url: '/tms/bank_account/' + id,
    method: 'delete'
  })
}

// 设置默认银行
export function setDefaultBankAccount(data) {
  return request({
    url: '/tms/bank_account/default_bank',
    method: 'put',
    data: data
  })
}

// 修改银行信息
export function updateBankAccount(data) {
  return request({
    url: '/tms/bank_account',
    method: 'put',
    data: data
  })
}
