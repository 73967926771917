<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    console.log("env:%c%s", "color: blue;", process.env.NODE_ENV);
    let url = window.location.href;
    if (process.env.NODE_ENV !== 'development') {
      // 强制https
      if (url.indexOf("https:") < 0) {
        url = url.replace("http:", "https:");
        window.location.replace(url);
      }
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  }
}
</script>
