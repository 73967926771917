<template>
  <div>
    <el-button size="mini" type="text" icon="el-icon-bank-card" @click="openDrawer">银行信息</el-button>
    <el-drawer
      :title="title"
      :visible.sync="visible"
      direction="rtl"
    >
      <el-row style="padding-left: 24px">
        <el-col :span="24">
          <el-form :inline="true" size="small" style="float: right">
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="handleAdd"
              >新增
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24">
          <el-card v-for="(item, index) in list" shadow="hover" :key="item.id" style="margin-bottom: 10px;">
            <el-descriptions border :column="2">
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  账户名
                </template>
                {{ item.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  开户行
                </template>
                {{ item.bankName }}
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="el-icon-bank-card"></i>
                  银行卡号
                </template>
                {{ item.number }}
              </el-descriptions-item>
              <el-descriptions-item label="状态">
                  <el-select v-model="item.status" placeholder="请选择" :disabled="bizId === undefined">
                    <el-option
                      v-for="o in [{
          value: 0,
          label: '未审核'
        },{
          value: 1,
          label: '通过'
        },{
          value: 3,
          label: '未通过'
        }]"
                      :key="o.value"
                      :label="o.label"
                      :value="o.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>

              <el-descriptions-item label="排序">
                <el-input type="number" v-model="item.sort" />
              </el-descriptions-item>
            </el-descriptions>
            <div class="bottom clearfix">

              <el-form inline style="float: right">
                <el-form-item>
                  <el-button type="text" size="mini" @click="onSaveBankAccount(item)">保存</el-button>
                </el-form-item>

                <el-form-item>
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="removeAccount(item)"
                  >
                    <el-button slot="reference" type="text" size="mini">删除</el-button>
                  </el-popconfirm>
                </el-form-item>

                <el-form-item>
                  <span v-if="item.defaultFlag" style="color: #606266">默认银行</span>
                  <el-button type="text" size="mini" @click="onSetDefault(item)" v-else>设为默认</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-drawer>

    <!-- 新增银行对话框 -->
    <el-dialog title="新增银行" :visible.sync="showDialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="账户名" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"/>
        </el-form-item>
        <el-form-item label="开户行" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入名称"/>
        </el-form-item>
        <el-form-item label="银行卡号" prop="number">
          <el-input v-model="form.number" placeholder="请输入名称"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCustomerBankAccount, addMyCustomerBankAccount,
  addNodeBankAccount,
  delBankAccount,
  listBankAccount, myListBankAccount,
  setDefaultBankAccount, updateBankAccount
} from '@/api/tms/bank_account'

export default {
  name: 'bank_drawer',
  props: {
    title: {
      type: String
    },
    bizId: {
      type: Number,
      default: undefined
    },
    bizType: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      showDialog: false,
      list: [],
      form: {

      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '账户名不能为空', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '开户行不能为空', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '银行卡不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    visible(v) {
      if (v) {
        this.getList()
      }
    }
  },
  methods: {
    openDrawer() {
      this.visible = true
    },
    getList() {
      const listFun = this.bizId ? listBankAccount : myListBankAccount;
      listFun({ type: this.bizType, bizId: this.bizId }).then(res => {
        this.list = res.data
      })
    },
    handleAdd() {
      this.showDialog = true
    },
    removeAccount(item) {
      delBankAccount(item.id).then(res => {
        if (res.data) {
          this.$modal.msgSuccess('删除成功')
        } else {
          this.$modal.msgWarning('删除失败')
        }
        this.getList()
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const addFun = this.bizType == 1 ? addNodeBankAccount : (this.bizId ?addCustomerBankAccount: addMyCustomerBankAccount);
          addFun({bizId: this.bizId, ...this.form}).then(() => {
            this.$modal.msgSuccess('新增成功')
            this.showDialog = false
            this.reset()
            this.getList()
          })
        }
      })
    },
    // 保存银行信息
    onSaveBankAccount(item) {
      updateBankAccount(item).then(res => {
        if (res.data) {
          this.$modal.msgSuccess('设置成功')
        } else {
          this.$modal.msgWarning('设置失败')
        }
        this.getList()
      })
    },
    // 设置默认银行
    onSetDefault(item) {
      setDefaultBankAccount(item).then(res => {
        if (res.data) {
          this.$modal.msgSuccess('设置成功')
        } else {
          this.$modal.msgWarning('设置失败')
        }
        this.getList()
      })
    },
    // 取消按钮
    cancel() {
      this.showDialog = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        name: null,
        bankName: null,
        number: null,
      }
      this.resetForm('form')
    },

  }
}
</script>

<style scoped>

</style>
