import request from '@/utils/request'

// 查询我的个人财务账套列表
export function myCustomerAccount() {
  return request({
    url: '/tms/finance_account/customer',
    method: 'get'
  })
}

// 查询我的网点财务账套列表
export function myNodeAccount() {
  return request({
    url: '/tms/finance_account/node',
    method: 'get'
  })
}

// 查询财务账套列表
export function listFinanceAccount(query) {
  return request({
    url: '/tms/finance_account/list',
    method: 'get',
    params: query
  })
}

// 查询财务账套详细
export function getFinanceAccount(id) {
  return request({
    url: '/tms/finance_account/' + id,
    method: 'get'
  })
}

// 新增财务账套
export function addFinanceAccount(data) {
  return request({
    url: '/tms/finance_account',
    method: 'post',
    data: data
  })
}

// 修改财务账套
export function updateFinanceAccount(data) {
  return request({
    url: '/tms/finance_account',
    method: 'put',
    data: data
  })
}

// 删除财务账套
export function delFinanceAccount(id) {
  return request({
    url: '/tms/finance_account/' + id,
    method: 'delete'
  })
}
