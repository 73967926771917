import { render, staticRenderFns } from "./register.vue?vue&type=template&id=77453986&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&id=77453986&rel=stylesheet%2Fscss&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_kq6vcxhoovkpo5ecbydii27d4a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/src/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77453986')) {
      api.createRecord('77453986', component.options)
    } else {
      api.reload('77453986', component.options)
    }
    module.hot.accept("./register.vue?vue&type=template&id=77453986&", function () {
      api.rerender('77453986', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/register.vue"
export default component.exports