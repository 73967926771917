import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'

// 平台首页
export function platIndex(query) {
  return request({
    url: '/tms/index/plat',
    method: 'get',
    params: query
  })
}

// 网点首页
export function nodeIndex(query) {
  return request({
    url: '/tms/index/node',
    method: 'get',
    params: query
  })
}

// 客户首页
export function customerIndex(query) {
  return request({
    url: '/tms/index/customer',
    method: 'get',
    params: query
  })
}
