<template>
  <div class="app-container">
    <div>
      <el-input
        placeholder="运单编号 车次编号 物流包编号 物流箱编号 。。。"
        prefix-icon="el-icon-search"
        v-model="code"
      >
      </el-input>
    </div>
    <el-divider content-position="left">{{ bizType }}</el-divider>
    <div>
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in info.logList"
          :key="index"
          :timestamp="item.createTime"
        >
          {{ item.content }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { getCodeInfo } from '@/api/tms/scan_code'

export default {
  name: 'CodeCmp',
  data() {
    return {
      code: undefined,
      info: {},
      bizType: '',
      codeTypeMap: {
        '04': '运单信息'
      }
    }
  },
  created() {
    this.code = this.$route.params && this.$route.params.code
    if (this.code) {
      getCodeInfo(this.code).then(res => {
        this.info = res.data
        this.bizType = this.codeTypeMap[res.bizType]
      })
    }
  }
}
</script>

<style scoped>

</style>
