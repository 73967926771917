<template>
  <el-form ref="form" :model="value" :rules="rules" label-width="80px" >
    <el-form-item label="开户银行" prop="bankName">
      <el-input v-model="value.financeAccount.defaultBankAccount.bankName" placeholder="请输入开户银行"/>
    </el-form-item>
    <el-form-item label="账户名" prop="name">
      <el-input v-model="value.financeAccount.defaultBankAccount.name" placeholder="请输入账户名" />
    </el-form-item>
    <el-form-item label="银行卡号" prop="number">
      <el-input v-model="value.financeAccount.defaultBankAccount.number" placeholder="请输入银行卡号" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
      <el-button type="danger" size="mini" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

import AddressAreaCode from '@/views/components/address/areaCode'
import {updateCustomerMine} from '@/api/tms/customer'
export default {
  name: 'bank',
  components: { AddressAreaCode },
  props: {
    value:{
      type:Object
    }
  },
  data() {
    return {
      // 表单校验
      rules: {

      }
    }
  },
  methods: {
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateCustomerMine(this.value).then(() => {
            this.$modal.msgSuccess("修改成功");
          });
        }
      });
    },
    close() {
      this.$tab.closePage();
    },
    /** 复制代码成功 */
    clipboardSuccess(){
      this.$modal.msgSuccess("复制成功");
    }
  }
}
</script>

<style scoped>

</style>
