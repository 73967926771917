import request from '@/utils/request'


// 扫码信息
export function getCodeInfo(code) {
  return request({
    url: '/tms/scan_code/' + code,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
