var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    ref: "cascader",
    attrs: { options: _vm.regionOptions },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.valueC,
      callback: function ($$v) {
        _vm.valueC = $$v
      },
      expression: "valueC",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }