<template>
  <div>
    <el-button size="mini" type="text" icon="el-icon-bank-card" @click="openDrawer">{{btName}}</el-button>
    <el-drawer
      :title="title"
      :visible.sync="visible"
      direction="rtl"
    >
      <el-table v-loading="loading" :data="list" style="margin: 10px">
        <el-table-column label="ID" prop="id"/>
        <el-table-column label="流水号" prop="code"/>
        <el-table-column label="交易金额" prop="value">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.value >= 0 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.value>0?'+':''}}{{scope.row.value}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="当前余额" prop="total"/>
        <el-table-column label="备注" prop="remark"/>
        <el-table-column label="创建时间" prop="createTime"/>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-drawer>
  </div>
</template>

<script>
import { listCustomerFinanceDetail, listNodeFinanceDetail, listPlatformFinanceDetail } from '@/api/tms/finance_detail'

export default {
  name: 'finance_detail_list',
  props: {
    bizId: {
      type:Number
    },
    bizType: {
      type:Number
    },
    title: {
      type: String
    },
    btName: {
      type: String,
      default: '财务明细'
    }
  },
  data() {
    return {
      visible: false,
      loading: true,
      list: [],
      total:0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 30,
      },
    }
  },
  methods: {
    openDrawer() {
      this.visible = true
      this.getList()
    },
    getList() {
      this.loading = true
      const listFun = this.bizType == 6 ?
        listPlatformFinanceDetail :
        ((this.bizType == 2 || this.bizType == 9)
          ? listNodeFinanceDetail : listCustomerFinanceDetail);
      listFun(this.bizId, this.queryParams).then(res => {
          this.list = res.data.records
          this.total = res.data.total
          this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
