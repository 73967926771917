var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c("el-input", {
            attrs: {
              placeholder: "运单编号 车次编号 物流包编号 物流箱编号 。。。",
              "prefix-icon": "el-icon-search",
            },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.bizType)),
      ]),
      _c(
        "div",
        [
          _c(
            "el-timeline",
            _vm._l(_vm.info.logList, function (item, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { timestamp: item.createTime } },
                [_vm._v(" " + _vm._s(item.content) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }