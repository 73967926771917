var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        {
          staticStyle: { border: "solid 1px #66b1ff" },
          attrs: { span: 22, offset: 1 },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.data.length == 0,
                  expression: "data.length == 0",
                },
              ],
              attrs: { data: _vm.data },
            },
            [
              _c("el-table-column", {
                attrs: { label: "账套ID", align: "center", prop: "id" },
              }),
              _c("el-table-column", {
                attrs: { label: "账套类型", align: "center", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 2
                          ? _c("div", [_vm._v("网点月结账套")])
                          : scope.row.type == 9
                          ? _c("div", [_vm._v("网点余额账套")])
                          : scope.row.type == 7
                          ? _c("div", [_vm._v("客户月结账套")])
                          : scope.row.type == 8
                          ? _c("div", [_vm._v("客户余额账套")])
                          : _c("div", [_vm._v("未知账套")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "账套编号", align: "center", prop: "code" },
              }),
              _c("el-table-column", {
                attrs: { label: "余额", align: "center", prop: "value" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("finance_detail_list", {
                          attrs: {
                            "biz-id": _vm.isOwn ? undefined : scope.row.bizId,
                            "biz-type": scope.row.type,
                            title: "财务明细 " + scope.row.code,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }