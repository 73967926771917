<template>
  <el-row>
    <el-col
      :span="22"
      :offset="1"
      style="border: solid 1px #66b1ff"
    >
      <el-table :data="data" v-loading="data.length == 0">
        <el-table-column label="账套ID" align="center" prop="id"/>
        <el-table-column label="账套类型" align="center" prop="type">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 2">网点月结账套</div>
            <div v-else-if="scope.row.type == 9">网点余额账套</div>
            <div v-else-if="scope.row.type == 7">客户月结账套</div>
            <div v-else-if="scope.row.type == 8">客户余额账套</div>
            <div v-else>未知账套</div>
          </template>
        </el-table-column>
        <el-table-column label="账套编号" align="center" prop="code"/>
        <el-table-column label="余额" align="center" prop="value"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <finance_detail_list :biz-id="isOwn?undefined:scope.row.bizId" :biz-type="scope.row.type" :title="'财务明细 '+ scope.row.code"/>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import Finance_detail_list from '@/views/finance/finance_detail_list'

export default {
  name: 'finance_account_list',
  components: { Finance_detail_list },
  props: {
    data: {
      type: Array
    },
    isOwn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
