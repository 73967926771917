<template>
  <el-cascader :options="regionOptions" v-model="valueC" @change="handleChange" ref="cascader" />
</template>

<script>
  import {
    regionDataPlus
  } from 'element-china-area-data'
  export default {
    name: 'AddressAreaCode',
    emits: ["change"],
    props: {
      value: {
        type: Array,
        default: []
      }
    },
    computed: {
      valueC: {
        get() {
          let data = this.value.map(item => String(item));
          if (3 > this.value.length) {
            data.push(""); // 添加全部
          }
          return data;
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    data() {
      return {
        // 行政区划选项
        regionOptions: regionDataPlus,
      }
    },
    methods: {
      handleChange() {
        this.$emit('change', this.$refs["cascader"].getCheckedNodes()[0].pathLabels);
      },
    }
  }
</script>

<style scoped>

</style>