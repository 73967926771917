<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="6" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <div class="text-center">
              <userAvatar :user="user"/>
            </div>
            <ul class="list-group list-group-striped">
              <li class="list-group-item">
                <svg-icon icon-class="user"/>
                用户名称
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="phone"/>
                手机号码
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <!--              <li class="list-group-item">
                              <svg-icon icon-class="email" />用户邮箱
                              <div class="pull-right">{{ user.email }}</div>
                            </li>-->
              <li class="list-group-item">
                <svg-icon icon-class="tree"/>
                所属部门
                <div class="pull-right" v-if="user.dept">{{ user.dept.deptName }} / {{ postGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="peoples"/>
                所属角色
                <div class="pull-right">{{ roleGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="date"/>
                创建日期
                <div class="pull-right">{{ user.createTime }}</div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeTab" >
            <!-- <el-tab-pane label="基本资料" name="userinfo">
               <userInfo :user="user" />
            </el-tab-pane>-->
            <el-tab-pane label="个人信息" name="customer">
              <customer v-model="customer"/>
            </el-tab-pane>
            <el-tab-pane label="财务账套" name="account">
              <bank_drawer :biz-type="2" title="个人银行信息" />
              <finance_account_list :data="financeList" :is-own="true"/>
            </el-tab-pane>
<!--            <el-tab-pane label="银行账户" name="bank">
              <bank v-model="customer"/>
            </el-tab-pane>-->
            <el-tab-pane label="修改密码" name="resetPwd">
              <resetPwd :user="user"/>
            </el-tab-pane>


          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from './userAvatar'
import userInfo from './userInfo'
import resetPwd from './resetPwd'
import { getUserProfile } from '@/api/system/user'
import {getCustomer} from '@/api/tms/customer'
import Customer from './customer'
import Bank from './bank'
import Finance_account_list from '@/views/finance/finance_account_list'
import { myCustomerAccount } from '@/api/tms/finance_account'
import Bank_drawer from '@/views/tms/node/bank_drawer'

export default {
  name: 'Profile',
  components: { Bank_drawer, Finance_account_list, Bank, Customer, userAvatar, userInfo, resetPwd },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: 'customer',
      customer: {
        areaCode: [],
      },
      financeList: []
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
      getCustomer().then(response => {
        this.customer = response.data;
      })
      myCustomerAccount().then(response => {
        this.financeList = response.data
      })
    },
  }
}
</script>

<style>
.danger {
  background: #E6A23C;
}

.success {
  background: #67C23A;
}
</style>
