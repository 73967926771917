import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'

// 查询消费者列表
export function listCustomer(query) {
  return request({
    url: '/tms/customer/list',
    method: 'get',
    params: query
  })
}

// 查询消费者详细
export function getCustomer(userId) {
  return request({
    url: '/tms/customer/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 查询消费者账套列表
export function getCustomerFinance(id) {
  return request({
    url: '/tms/customer/finance/' + id,
    method: 'get'
  })
}

// 新增消费者
export function addCustomer(data) {
  return request({
    url: '/tms/customer',
    method: 'post',
    data: data
  })
}

// 修改消费者
export function updateCustomer(data) {
  return request({
    url: '/tms/customer',
    method: 'put',
    data: data
  })
}

// 修改消费者
export function updateCustomerMine(data) {
  return request({
    url: '/tms/customer/mine',
    method: 'put',
    data: data
  })
}

// 删除消费者
export function delCustomer(userId) {
  return request({
    url: '/tms/customer/' + userId,
    method: 'delete'
  })
}

// 查询消费者选项列表
export function queryCustomer(query) {
  return request({
    url: '/tms/customer/query',
    method: 'get',
    params: query
  })
}
