<template>
  <el-form ref="form" :model="value" :rules="rules" label-width="80px">
    <el-form-item label="手机" prop="mobile">
      <el-input v-model="value.mobile" placeholder="手机" disabled />
    </el-form-item>
    <el-form-item label="姓名" prop="realName">
      <el-input v-model="value.realName" placeholder="请输入名称"/>
    </el-form-item>
    <el-form-item label="所在地区" prop="areaCode">
      <address-area-code v-model="value.areaCode"/>
    </el-form-item>
    <el-form-item label="地址" prop="address">
      <el-input v-model="value.address" placeholder="请输入地址"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
      <el-button type="danger" size="mini" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

import AddressAreaCode from '@/views/components/address/areaCode'
import {updateCustomerMine} from '@/api/tms/customer'
export default {
  name: 'bank',
  components: { AddressAreaCode },
  props: {
    value:{
      type:Object
    }
  },
  data() {
    return {
      // 表单校验
      rules: {
        realName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '所在地区不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateCustomerMine(this.value).then(() => {
            this.$modal.msgSuccess("修改成功");
           });
        }
      });
    },
    close() {
      this.$tab.closePage();
    }
  }
}
</script>

<style scoped>

</style>
