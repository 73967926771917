<template>
  <!-- 通用表格 -->
  <div
    class="commonTable"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <div
      :style="option.pageType ? 'padding-bottom: 10px' : 'padding-bottom: 80px'"
    >
      <!-- 顶部盒子（用于存放各种操作按钮） -->
      <div class="topBox">
        <div class="left"></div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="刷新" placement="top">
            <!-- 刷新按钮 -->
            <el-button
              size="mini"
              circle
              icon="el-icon-refresh"
              @click="getData({ pageNum: 1 })"
            />
          </el-tooltip>
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="selectionChange"
      >
        <!-- 多选框 默认不开启 -->
        <el-table-column
          type="selection"
          width="50"
          align="center"
          v-if="option.needSelection"
        />
        <!-- 循环生成表格项 -->
        <template v-for="(item, index) in option.column">
          <!-- 使用具名插槽自定义内容 -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            v-if="item.slot"
            :key="index"
            align="center"
          >
            <template slot-scope="scope">
              <slot :name="item.prop" :data="scope.row"></slot>
            </template>
          </el-table-column>
          <!-- -------- 非插槽 start -------- -->
          <template v-else>
            <!-- 普通 -->
            <el-table-column
              v-if="!item.type"
              :prop="item.prop"
              :label="item.label"
              :key="index"
              :formatter="item.formatter || publicFormatter"
              align="center"
            ></el-table-column>
            <!-- 展开行 -->
            <el-table-column
              v-if="item.type == 'expand'"
              type="expand"
              label="更多信息"
              width="75"
              :key="index"
            >
              <template slot-scope="scope">
                <slot :name="item.prop" :data="scope.row"></slot>
              </template>
            </el-table-column>
            <!-- 时间 -->
            <el-table-column
              v-if="item.type == 'time'"
              :label="item.label"
              align="center"
              :key="index"
            >
              <template slot-scope="scope">
                <div>{{ scope.row[item.prop][0] }}</div>
                <div>{{ scope.row[item.prop][1] }}</div>
              </template>
            </el-table-column>
          </template>
          <!-- -------- 非插槽 end -------- -->
        </template>
      </el-table>
    </div>
    <!-- 分页 -->
    <div :class="option.pageType ? option.pageType + 'PageBox' : 'pageBox'">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="pageParam.pageNum"
        :total="total"
        @size-change="sizeChange"
        @current-change="pageNumChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      loading: false, // loading开关
      tableData: [], // 表格数据
      total: 0,
      // 分页参数
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      },
      // 公共的内容格式化
      publicFormatter: (row, column, value) => {
        return value === null || value === undefined ? "-" : value;
      },
      selectionData: [], // 多选框的数据
    };
  },
  props: {
    // 表格配置项
    option: {
      type: Object,
    },
    // 请求参数
    queryParam: {
      type: Object,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取数据
    getData(param = {}) {
      // 判断是否有参数
      if (param.pageNum) {
        this.$set(this.pageParam, "pageNum", param.pageNum);
      }
      this.loading = true;
      request({
        url: this.option.url,
        method: this.option.method || "get", // 默认是 get 请求
        params: { ...this.queryParam, ...this.pageParam },
      })
        .then((res) => {
          if (res.code == 200) {
            // 是否需要处理数据结构
            if (this.option.dealFormation) {
              res = this.option.dealFormation(res);
            }
            // 处理表格数据
            this.tableData = this.option.dealData
              ? this.option.dealData(res.rows)
              : res.rows;
            this.total = res.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 多选框变化
    selectionChange(selection) {
      this.selectionData = selection;
    },
    // 每页条数 发生变化
    sizeChange(size) {
      this.$set(this.pageParam, "pageNum", 1);
      this.$set(this.pageParam, "pageSize", size);
      this.getData();
    },
    // 页码 发生变化
    pageNumChange(num) {
      this.$set(this.pageParam, "pageNum", num);
      this.getData();
    },
  },
};
</script>


<style lang="scss" scoped>
.commonTable {
  position: relative;
  .topBox {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  .pageBox {
    position: fixed;
    bottom: 30px;
    right: 100px;
    background: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
    z-index: 1000;
  }
  // 分页位于顶部
  .topPageBox {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
