var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.value, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "手机", prop: "mobile" } },
        [
          _c("el-input", {
            attrs: { placeholder: "手机", disabled: "" },
            model: {
              value: _vm.value.mobile,
              callback: function ($$v) {
                _vm.$set(_vm.value, "mobile", $$v)
              },
              expression: "value.mobile",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "姓名", prop: "realName" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入名称" },
            model: {
              value: _vm.value.realName,
              callback: function ($$v) {
                _vm.$set(_vm.value, "realName", $$v)
              },
              expression: "value.realName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "所在地区", prop: "areaCode" } },
        [
          _c("address-area-code", {
            model: {
              value: _vm.value.areaCode,
              callback: function ($$v) {
                _vm.$set(_vm.value, "areaCode", $$v)
              },
              expression: "value.areaCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "地址", prop: "address" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入地址" },
            model: {
              value: _vm.value.address,
              callback: function ($$v) {
                _vm.$set(_vm.value, "address", $$v)
              },
              expression: "value.address",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }