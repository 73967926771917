var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-descriptions",
        { attrs: { border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "当前版本" } }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "环境" } }, [
            _vm._v(_vm._s(_vm.env)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }