import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'

// 查询网点财务明细列表
export function listNodeFinanceDetail(nodeId, query) {
  return request({
    url: '/tms/finance_detail/node_list/'+parseStrEmpty(nodeId),
    method: 'get',
    params: query
  })
}

// 查询客户财务明细列表
export function listCustomerFinanceDetail(userId, query) {
  return request({
    url: '/tms/finance_detail/customer_list/'+parseStrEmpty(userId),
    method: 'get',
    params: query
  })
}

// 查询平台财务明细列表
export function listPlatformFinanceDetail(platformId, query) {
  return request({
    url: '/tms/finance_detail/platform_list/'+platformId,
    method: 'get',
    params: query
  })
}

// 查询财务明细详细
export function getFinanceDetail(id) {
  return request({
    url: '/tms/finance_detail/' + id,
    method: 'get'
  })
}

// 新增财务明细
export function addFinanceDetail(data) {
  return request({
    url: '/tms/finance_detail',
    method: 'post',
    data: data
  })
}
